<header id="main_header" class="d-flex justify-content-between align-items-center">
  <div class="header d-flex align-items-center">
      <nav class="nav-bar d-flex justify-content-between align-items-center">
        <a [routerLink]="['']" class="a_header">
          <div class="header_brand d-flex align-items-center">
            <img src="../assets/images/logo/occasports_logo.png">
            <div class="brand px-3 text-right d-flex align-items-center">
              <h1 class="h1_occa">Occa<span class="brand_sports h1_sports">sports</span></h1>
              <!-- <span class="sport_design brand_sports">Sport Design</span> -->
            </div>
          </div>
        </a>
          <div class="header_nav">  
            <div class="d-lg-none hamburger" [class.activeHamburger]="activeHamburger == true" (click)="slidemenu()">
              <!-- <div class="bar"> </div> -->
              <span></span>
              <span></span>
              <span></span>
            </div>

              <ul class="nav_menu" [class.activeMenu]="activeMenu == true">
                <li class="d-lg-none menu_item px-3" routerLinkActive="active" (click)="slidemenu(); navigateToSection('top')"><a [routerLink]="['']">Accueil</a></li>
                <li class="menu_item px-3" routerLinkActive="active" (click)="slidemenu(); navigateToSection('top')"><a [routerLink]="['tenuesclub']">Tenues de club</a></li>
                <li class="menu_item px-3" routerLinkActive="active" (click)="slidemenu(); navigateToSection('top')"><a [routerLink]="['vestesarchery']">Vestes tir à l'arc</a></li>
                <li class="menu_item px-3" routerLinkActive="active" (click)="slidemenu(); navigateToSection('top')"><a [routerLink]="['realisations']">Réalisations</a></li>
                <li class="menu_item px-3" routerLinkActive="active" (click)="slidemenu(); navigateToSection('top')"><a [routerLink]="['contact']">Contact</a></li>
              </ul>
          </div>
      </nav>
  </div>
</header>
  
  <section class="content_section"><router-outlet></router-outlet></section>
  
  <footer class="container-fluid">
    <nav class="footer_nav py-4">
      <ul class="footer_nav_menu d-flex flex-column flex-sm-row justify-content-center align-items-center">
        <li class="menu_item px-3" routerLinkActive="active"><a [routerLink]="['aboutus']" (click)="navigateToSection('top')">Qui sommes-nous ?</a></li>
        <li class="menu_item px-3" routerLinkActive="active"><a [routerLink]="['impressions']" (click)="navigateToSection('top')">Types d'impression</a></li>
        <li class="menu_item px-3" routerLinkActive="active"><a [routerLink]="['sizeguide']" (click)="navigateToSection('top')">Guide des tailles</a></li>
        <li class="menu_item px-3" routerLinkActive="active"><a [routerLink]="['cgv']" (click)="navigateToSection('top')">CGV</a></li>
      </ul>
    </nav>
    <div class="bottom_footer row flex-column align-items-center align-items-md-start flex-sm-row justify-content-center">
      <div class="footer_left col-12 col-md-5">
        <div class="download_catalog d-flex justify-content-center justify-content-md-start align-items-center pb-4">
          <div class="pr-2">Téléchargez <span>notre catalogue</span></div>
          <a href="../../../assets/files/OCCASPORTS_Catalogue_2020_2021.pdf" download><div class="download_button px-3 py-1">></div></a>
        </div>
        <div class="inpi_infos d-flex flex-column flex-xs-row align-items-center align-items-md-start">
          <div>
            <img class="pr-2" src="../assets/images/logo/Logo_INPI.jpg" alt="">
          </div>
          <p class="text-center text-md-left">Tous nos modèles sont déposes.<br>Toute reproduction ou copie est interdite sous peine de poursuites</p>
        </div>
      </div>
      <div class="footer_right col-12 col-md-5 col-xl-2 pt-3 pt-sm-0">
          <div class="contact_us pt-3 pt-md-0 d-flex flex-column align-items-center align-items-md-start">
            <div class="pb-2"><span>Contactez</span>-nous</div>
            <p>Occa<span>sports</span></p>
            <p>8 impasse du 14 juillet, 94800 Villejuif</p>
            <p>Tel. +33 (0)6 51 96 30 45</p>
            <p>contact[at]occasports.fr</p>
          </div>
      </div>
    </div>
  
  </footer>