import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';

@Component({
  selector: 'app-client',
  templateUrl: './client.component.html',
  styleUrls: ['./client.component.css']
})
export class ClientComponent implements OnInit {

  activeMenu:boolean = false;
  activeHamburger: boolean = false;

  constructor(private router: Router) { }

  ngOnInit(){
    this.navigateToSection('top');
  }

  navigateToSection(section: string) {
    if(section == 'top')
    {
      window.scrollTo(0, 0);
    }
    else{
      window.location.hash = '';
      window.location.hash = section;
    }
  }

  slidemenu(){
    this.activeMenu == true ? this.activeMenu = false : this.activeMenu = true;
    this.activeHamburger == true ? this.activeHamburger = false : this.activeHamburger = true;
  }

}
